<template>
  <section class="container list">
    <div class="text-center empty-state" v-if="isEmpty">
      <h1>{{ emptyText }}</h1>
    </div>

    <ScannedListCounts v-if="!isEmpty && counts" :counts="counts" />

    <div v-if="!isEmpty && tabs" class="tabs">
      <button
        v-for="(tab, index) in tabs.values"
        :key="index"
        type="button"
        class="btn tab"
        :class="tabClasses(tab, index)"
        @click="tabs.onTabSelect(tab)"
      >
        {{ $t(`tabs.${tab}`) }}
      </button>
    </div>

    <div v-if="!isEmpty" class="items">
      <slot />
    </div>
  </section>
</template>

<script>
import ScannedListCounts from "@/components/ScannedList/Counts";

export default {
  name: "ScannedList",
  components: { ScannedListCounts },
  props: {
    emptyText: {
      type: String,
      required: true
    },
    isEmpty: {
      type: Boolean,
      required: true
    },
    counts: {
      type: [Array, null],
      default: null
    },
    tabs: {
      type: [Object, null],
      default: null
    }
  },

  methods: {
    tabClasses(tab, index) {
      const maxTabIndex = this.tabs.values.length - 1;

      return {
        active: this.tabs.currentTab === tab,
        "tab-left": index === 0,
        "tab-right": index === maxTabIndex
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  .empty-state {
    margin-top: 30px;
  }

  .tabs {
    padding-bottom: 1rem;
  }

  .items {
    border-top: 1px solid $grey;
  }
}
</style>
