<template>
  <div class="counts d-flex justify-content-evenly">
    <div
      v-for="(count, index) in filteredCounts"
      :key="index"
      class="d-flex align-items-center gap-1"
      :class="count.color"
    >
      <component class="count-icon" :is="count.icon" :size="countIconSize" />
      <p class="count-text" :style="{ fontSize: `${countTextSize}rem` }">
        {{ count.value }}
      </p>
    </div>
  </div>
</template>

<script>
const COUNT_SIZES = {
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small"
};

export default {
  name: "ScannedListCounts",
  props: {
    counts: {
      type: Array,
      required: true
    }
  },

  computed: {
    filteredCounts() {
      return this.counts.filter(({ value }) => value > 0);
    },

    countIconSize() {
      const size = this.getSize();

      switch (size) {
        case COUNT_SIZES.LARGE:
          return 30;
        case COUNT_SIZES.MEDIUM:
          return 40;
        default:
          return 50;
      }
    },

    countTextSize() {
      const size = this.getSize();

      switch (size) {
        case COUNT_SIZES.LARGE:
          return 2;
        case COUNT_SIZES.MEDIUM:
          return 2.5;
        default:
          return 3.25;
      }
    }
  },

  methods: {
    getSize() {
      if (this.counts.some(count => count.value >= 1000)) {
        return COUNT_SIZES.LARGE;
      }

      if (this.counts.some(count => count.value >= 100)) {
        return COUNT_SIZES.MEDIUM;
      }

      return COUNT_SIZES.SMALL;
    }
  }
};
</script>

<style lang="scss" scoped>
.counts {
  padding-block: 1rem;
  overflow-x: hidden;
}

.count {
  &-icon {
    line-height: 1;
  }

  &-text {
    font-weight: 700;
  }
}
</style>
